.signaturev2 {
  /*
*******************************************
Template Name: Clear Law | Payday Loans
Author: Allen Mathew
Date: 17-05-2023
Template By: Vandalay Designs
Copyright 2022-2023 Vandalay Business Solution

* This file contains the styling for laonclaims.co.uk, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - HEADER SECTION
04 - MAIN SECTION
05 - MODAL 
06 - FOOTER 
07 - MEDIA QUERY

============================================
============================================
*/

  /*================================================
01 - Sass Variables
==================================================*/

  $color_1: #155724;
  $color_2: #ffffff;
  $color_3: #ff357a;
  $color_4: #eee;
  $color_5: #343a40;
  $color_6: #f1f1f2;
  $color_7: #000;

  $background_color_1: #232355;
  $background_color_2: #d4edda;
  $background_color_3: #192733;
  $background_color_4: #ff0;
  $background_color_5: #343a40;
  $background_color_6: #ff357a;
  $background_color_7: #fff;

  // ======font styles===============

  $font_size_1: 24px;

  /*================================================
02 - Sass Mixins
==================================================*/

  /*================================================
03 - HEADER SECTION
==================================================*/

  header {
    .brand_logo {
      text-align: center;
      padding: 1rem 0;

      img {
        height: 40px;
      }
    }
  }

  /*================================================
03 - MAIN SECTION
==================================================*/

  .colouredBox {
    background-color: $background_color_1;
    color: $color_2;
    font-family: "Lato", sans-serif;
    display: inline-block;
    width: 100%;
    margin-top: -1px;

    .stepProgress {
      position: absolute;
      top: -55px;

      .progressLine {
        border-top: 2px solid $color_3;
        position: absolute;
        width: 62%;
        right: 30px;
        top: 20px;
        z-index: -1;
      }

      .btn-circle {
        border-radius: 2rem;
        height: 40px;
        width: 40px;
        background-color: $color_2;
        color: $color_7;
        margin: 0 12px;
      }

      .active {
        background-color: $background_color_6 !important;
        color: $color_2;
        border: $color_3;
      }
    }

    .btn-custom1 {
      background-color: $background_color_6;
      color: $color_2;
    }

    // ============= 1st-accordion =============//

    .accordion-list {
      margin-top: 20px;
      margin-bottom: 40px;

      .accdn-cnt1 {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        background-color: $background_color_2;
        color: $color_1;
        padding: 20px;
        margin: 0 auto 15px auto;
        border: 1px solid $color_4;
        border-radius: 5px;
        cursor: pointer;

        .title {
          i {
            vertical-align: middle;
            font-size: 28px;
            cursor: pointer;
          }
        }

        h3 {
          position: relative;
          display: block;
          width: 100%;
          height: auto;
          padding: 0 0 0 0;
          margin: 0;
          font-size: 28px;
          letter-spacing: 0.01em;
          cursor: pointer;
        }

        .answer {
          position: relative;
          display: block;
          width: 100%;
          height: auto;
          margin: 0;
          padding: 0;
          cursor: pointer;

          p {
            position: relative;
            display: block;
            font-weight: 300;
            padding: 10px 0 0 0;
            cursor: pointer;
            line-height: 150%;
            margin: 0 0 15px 0;
            font-size: 16px;
          }
        }
      }
    }

    // ============= 1st-accordion =============//

    // ============= 2nd-accordion section =============//
  }

.acordn-sec1{
.accordion-item{
  font-family: "Lato", sans-serif;
  margin-top: 10px;
  .accordion-header {
    .accordion-button{
      font-size: 28px !important;
      
    }
    
  }
  .accordion-body {

            width: 100%;
            height: auto;
 
            .container-1 {
              h4 {
                font-size: 18px;
                color: $color_7;
  
                font-weight: 700;
                margin-bottom: 20px;
              }
  
              p {
                font-size: 14px;
                color: $color_7;
                font-weight: 300;
  
                .bck-clr1 {
                  background-color: $background_color_4;
                }
              }
  
              .lst_1 {
                font-size: 14px;
                padding-right: 10px;
                margin-top: 18px;
                color: $color_7;
                list-style-type: decimal;
  
                &::before {
                  content: " ";
                }
              }
  
              .mrgn_1 {
                margin-left: 25px;
  
                .lst_2 {
                  font-size: 14px;
                  padding-right: 10px;
                  margin-top: 18px;
                  color: $color_7;
                  list-style-type: disc;
  
                  &::before {
                    content: " ";
                  }
                }
              }
  
              .mrgn_2 {
                margin-left: 40px;
  
                .lst_3 {
                  font-size: 14px;
                  padding-right: 10px;
                  margin-top: 18px;
                  color: $color_7;
                  list-style-type: lower-alpha;
  
                  &::before {
                    content: "";
                  }
                }
              }
  
              table {
                width: 100%;
                color: $color_7;
              }
  
              table,
              th,
              td {
                border: 1px solid $color_7;
                border-collapse: collapse;
                font-size: 14px;
                padding: 10px;
                text-align: center;
              }
            }
}
}
}
.efct_filed {
  animation: blinkBG 1.5s infinite;
}
 
    .btn-custom1 {
      background-color: $background_color_6;
      color: $color_2;
    }

  //   // ============= 2nd-accordion section =============//
   

  /*================================================
05 - MODAL
==================================================*/

   .modl_1 {
    font-family: "Lato", sans-serif;
    color: $color_7;

    .modal-title {
      font-size: 20px;
    }

    .close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: $color_7;
      text-shadow: 0 1px 0 $color_2;
      opacity: 0.5;
      background-color: transparent !important;
      border: 0 !important;
      padding: 0 !important;
    }

    .canvasBkg {
      background-color: $background_color_7;
      width: 100%;
      max-width: 100%;
      height: 200px;
      margin-bottom: 1rem;
      display:flex;
      justify-content: center;

      #signCanvas {
        width: 400px;
        max-width: 100%;
        height: 200px;
        background: $color_6 url(../../../src/assets/img/signature.png) no-repeat center;
        border: 2px solid #ccc;
        border-radius: 1rem;
      }
    }

    .custom-checkbox {
      min-height: 40px !important;
    }

    .custom-control {
      position: relative;
      z-index: 1;
      display: block;
      min-height: 1.5rem;
      padding-left: 1.5rem;
    }

    .custom-control-input {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 1;
    }

    .custom-control-label {
      text-align: left;
    }

    .btn-custom1 {
      background-color: $background_color_6;
      color: $color_2;
    }

    .modal-footer {
      border-top: none !important;
    }
   }

  /*================================================
06 - FOOTER
==================================================*/

  .bg-dark {
    font-family: "Titillium Web", sans-serif !important;
    background-color: $background_color_5 !important;
    color: #fff;

    .footer-link {
      margin-top: 20px;
      display: inline-block;
      border-right: 1px solid;
      height: 21px;
      line-height: unset !important;
      font-size: 13px !important;

      a {
        padding: 5px;
        font-size: 14px;
        color: #fff;
      }

      .ft-p1 {
        font-size: 13px !important;
      }
    }
  }

  /*================================================
07 - MEDIA QUERY
==================================================*/

  @media only screen and (max-width: 768px) {
  

    .acordn-sec1 {
      .accordion-item  {
        .accdn-cnt1 {
          .accordion-header {
            .accordion-button{
              font-size: 24px !important;
              
            }
      }
    }
  }
}
  }

  @media only screen and (max-width: 600px) {

    .ps-5,
    .px-5 {
      padding: 0px !important;
    }

    .my-5 {
      margin: 0 !important;
    }

    .colouredBox {
      .stepProgress {
        position: relative !important;
        top: 0px !important;
      }
    }
  }

  @media only screen and (max-width: 450px) {
  

    .acordn-sec1 {
      .accordion-item {
        .accordion-header {
          .accordion-button{
            font-size: 18px !important;
            
          }
          
        }
        .accordion-body {
          .tbl_ovrflw {
            overflow-x: auto;
          }

          
        }

        .accordion-body {
          .container-1 {

            table,
            th,
            td {
              font-size: 11px !important;
              padding: 2px !important;
            }

            h4 {
              font-size: 14px !important;
              margin-bottom: 10px !important;
            }

            .lst_1 {
              font-size: 12px !important;
            }

            .lst_2 {
              font-size: 12px !important;
            }

            .lst_3 {
              font-size: 12px !important;
            }

            p {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }

  .sign_msg{
    color: #fff;
    background: #ff0000;
    width: 100%;
    padding: 2px 5px;
    text-align: center;
    border-radius: 4px;
    font-size: 13px;
   
  }
  .efct_filed {
    animation: blinkBG 1.5s infinite;
  }
}