.CL_LS_V3 {
  /*
  *******************************************
  Template Name: Clear Low
  Author: ABC
  Date: 27-12-2022
  Template By: Vandalay Designs
  Copyright 2020-2022 Vandalay Business Solution

  * This file contains the styling for laonclaims.co.uk, this
  is the file you need to edit to change the look of the
  site.

  This files contents are outlined below >>>>

  *** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
  *******************************************

  ============================================
  ==== T A B L E   O F   C O N T E N T S =====
  ============================================
  01 - Sass Variables
  02 - Sass Mixins
  03 - RESET
  04 - BODY 
  05 - HEADER SECTION
  06 - MAIN SECTION
  07 - FOOTER 
  09 - MEDIA QUERY

  ============================================
  ============================================
  */

  /*================================================
  01 - Sass Variables
  ==================================================*/

  $color_1: #232355;
  $color_2: #eb2164;
  $color_3: #000;
  $color_4: #fff;
  $color_5: #ff357a;
  $color_6: #f1f1f2;
  $color_7: #e3e3e3;
  $color_8: #9c9c9c;
  $color_8: #343a40;
  $popTransp: rgba(0, 0, 0, 0.425);
  // ======font styles===============

  $font_family_1: "Titillium Web",
  sans-serif;

  $font_size_1: 14px;
  $font_size_2: 22px;
  $font_size_3: 28px;
  $font_size_4: 26px;
  $font_size_5: 18px;
  $font_size_6: 15px;
  $font_size_7: 17px;
  $font_size_8: 20px;

  $font-bold: bold;
  $font-w600: 600;
  $font-w700: 700;
  $font-w900: 900;

  $shadowLight: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  $shadow01: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
  rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  // ======border===========
  .border {
    border: 1px solid $color_3;
  }

  /*================================================
  02 - Sass Mixins
  ==================================================*/
.font-style{
  font-size: 25px;
}
  @mixin bnt-style {
    background: $color_5;
    color: $color_4;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: $font-w600;
    display: inline-block;
    text-decoration: none;
    transition: ease-in 0.4s;
    outline: navajowhite;
    border: 0;

    &:hover,
    &:focus {
      color: $color_4;
      transition: ease-in 0.4s;
      transform: translateY(-5px);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      background: $color_2;
    }
  }

  @mixin comTitle($textSize) {
    font-size: ($textSize);
    font-family: $font_family_1;
  }

  /*================================================
    End Sass Mixins
  ==================================================*/
  .com_bnt {
    @include bnt-style;
  }

  /*================================================
  03 - RESET
  ==================================================*/
  // * {
  //   box-sizing: border-box;
  //   padding: 0;
  //   margin: 0;
  //   outline: 0;
  //   text-decoration: none;
  // }

  p {
    font-size: 16px;
    line-height: 22px;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    line-height: 20px;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
    }
  }

  .d_hide,
  .dnone {
    display: none !important;
  }

  /*================================================
  04 - BODY STYLES
  ==================================================*/


  html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-size: 100%;
  }

  font-family: $font_family_1 !important;
  font-size: 1rem;
  line-height: 1rem;
  background-color: $color_1;
  color: $color_4;

  /*================================================
  END - BODY STYLES
  ==================================================*/

  .under_line {
    text-decoration: underline;
  }

  .comm_title {
    @include comTitle(2rem);
    color: $color_1;
    font-weight: $font-w600;
    margin-bottom: 1rem;
  }

  .btn-lgr {
    max-width: 600px;
    padding: 1.5rem 3rem;
    font-size: 1.8rem;
  }

  /*================================================
  05 - MAIN SECTION
  ==================================================*/
  .main-start {

    p {
      font-size: 20px;
      color: $color_2;
    }

    &.bg {
      &::before {
        background: url("../img/img-bg1.png") no-repeat;
        content: "";
        height: 100px;
        width: 100%;
        display: block;
        background-size: cover;
      }
    }
  }

  .sec-nav-bars {
    background: $color_4;
    border-bottom: 1px solid $color_7;

    .logo {
      display: inline-block;
      padding: 5px;

      img {
        width: 200px;
      }
    }
  }

  .user-type-toggle {
    width: 100%;

    label {
      background: $color_6;

      &.active {
        background: $color_2;
        color: $color_4;
        font-weight: bold;
      }

      input {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .lookup-btn {
    font-size: 22px;
  }

  .btn-fr {
    .btn {
      border: 0;

      label {
        cursor: pointer;
      }

      input {
        opacity: 0;
        visibility: hidden;
        position: absolute;
      }

      &.current,
      &.current1 {
        background: $color_2;
        color: $color_4;
      }
    }
  }
  .form-wrap{
    .error {
      background: $color_2;
      margin-top: 4px;
      border-radius: 5px;
      padding: 2px 6px;
      font-size: 14px;
  
  
    }
    .form-style {
      outline: none;
      border: 0;
      &.form-select {
        padding: 12px 10px;
      }
    }
    .lookup-filed {
      input {
        width: 70%;
      }
    }
    
    .accordion-item {
      border-radius: 5px;
      .accordion-header {
        .accordion-button:not(.collapsed) {
          color:#343a40 !important;
          background-color: white !important;
          box-shadow: none !important;
          &::after {
            background-image: url("../img/arrow-down-solid.svg");
            background-size: 100% 100%;
            filter: invert(21%) sepia(15%) saturate(388%) hue-rotate(169deg) brightness(94%) contrast(94%);
          }
        }
        .accordion-button {
          padding: 20px;
          border-radius: 5px;
          color:#343a40;
          font-weight: 700;
          font-size: 28px !important;
          &::after {
            background-image: url("../img/arrow-down-solid.svg");
            background-size: 100% 100%;
            height: 28px !important;
            filter: invert(21%) sepia(15%) saturate(388%) hue-rotate(169deg) brightness(94%) contrast(94%);          
          }
          &:focus {
            z-index: 0 !important;
            border-color: white !important;
            outline: 0;
            box-shadow: none !important;
          }
        }
  
        
      }
      .accordion-body {
        h4 {
          font-size: 18px;
          color: $color_3;
  
          font-weight: 700;
          margin-bottom: 20px;
        }
  
        p {
          font-size: 14px;
          color: $color_3;
          font-weight: 300;
        
        }
        .lst_1 {
          font-size: 14px;
          padding-right: 10px;
          margin-top: 18px;
          color: $color_3;
          list-style-type: decimal;
          &::before {
            content: " ";
          }
        }
        .mrgn_1 {
          margin-left: 25px;
          .lst_2 {
            font-size: 14px;
            padding-right: 10px;
            margin-top: 18px;
            color: $color_3;
            list-style-type: disc;
            &::before {
              content: " ";
            }
          }
        }
        .mrgn_2 {
          margin-left: 40px;
          .lst_3 {
            font-size: 14px;
            padding-right: 10px;
            margin-top: 18px;
            color: $color_3;
            list-style-type: lower-alpha;
            &::before {
              content: "";
            }
          }
        }
  
        table {
          width: 100% ;
          color: $color_3 ;
        }
  
        table,
        th,
        td {
          border: 1px solid $color_3 ;
          border-collapse: collapse ;
          font-size: 14px ;
          padding: 10px ;
          text-align: center ;
        }
      }
    }
  }
  .section-next {
    background: $color_4 url(../img/bottomwave.png) repeat-x;
    background-position: top center;
    padding: 60px 0 !important;
    width: 100%;

    .btn-next {
      font-size: 20px;
      margin-top: 60px;
    }
    .next-btn{
      position: relative;
      bottom: 7rem;
      margin-top: 0 !important;
    }
  }
  .sec-nxt1{
    padding-top: 40px !important;
    padding-bottom:20px !important;
  }

  .form-wrap {
    // .frm-img{
    //   padding-left:1rem;
    //   padding-right:1rem !important;
    // }
    .inpt-img{
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      height:100%;
      // .img-height{
        
      // }

    }
    
    .error {
      background: #ff0000;
      border-radius: 5px !important;
      padding: 2px 55px;
      font-size: 14px;
      width: 100% !important;
    }

    .error1 {
      background: #ff0000;
      border-radius: 5px;
      font-size: 14px;
      width: 100% !important;
      padding: 5px;
    }

    .iva_error {
      background: #ff0000;
      /* margin-top: 4px; */
      border-radius: 5px;
      padding: 2px 7px;
      font-size: 12px;
      width: fit-content;
    }

    .form-style {
      outline: none;
      border: 0;

      &.form-select {
        padding: 12px 10px;
      }
    }

    .lookup-filed {
      input {
        width: 70%;
      }
    }
    
  }
  .imgArrow{
      max-width: 3%;
  }
  .select1 {
    margin-top: -20px;
  }

  .popup-style {
    background: $color_1;
    color: $color_4;

    h1 {
      font-size: 26px;
      line-height: 30px;
    }
  }

  .address-btn {
    background: $color_2;
    padding: 10px 20px;
    color: $color_4;
    border-radius: 5px;
    outline: none;
    border: none;
  }




  .slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  /*================================================
  07 - FOOTER SECTION
  ==================================================*/


  footer {
    background: $color_8;
    color: $color_4;
    font-size: 13px;

    .ft-menu {
      .footer-link {
        display: inline-block;
        border-right: 1px solid;
        height: 21px;
        line-height: unset !important;

        a {
          padding: 5px;
          font-size: 14px;
          color: $color_4;
        }
      }
    }

    p {
      font-size: 14px;
    }
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  /*================================================
  09 - MEDIA QUERY
  ==================================================*/

  $break-large: 1367px;
  $break-ipad-pro: 1200px;
  $break-min-ipad: 768px;
  $break-max-ipad: 991px;
  $break-min-tab: 577px;
  $break-max-tab: 768px;
  $break-mob: 576px;
  $break-smlmob: 391px;
  $break-ex_smlmob: 322px;

  @media (min-width: 1400px) {
    .container {
      max-width: 1140px !important;
    }
  }


  // maxwidth  991px
  @media screen and (max-width: $break-max-ipad) {
    .com_bnt {
      @include bnt-style;
      padding:20px;
    }

    .wrap-question {

      .col-video,
      .right-form {
        width: 100%;
      }
    }

    .main-start {
      &.bg {
        &::before {
          height: 60px;
        }
      }
    }

    .form-wrap {
      .lookup-filed {
        input {
          width: auto;
        }
      }
    }
  }




  @media screen and (max-width: $break-max-tab) {
    .car-type {
      text-align: center;
      font-size: 15px;

      i {
        font-size: 15px;
      }
    }

    .lookup-btn {
      border-radius: 10px !important;
    }

    .form-style {
      border-radius: 10px !important;
      
    }
    .frm-img{
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .form-wrap {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            font-size: 20px !important;
        }
      }
  }
    }

    .com_bnt{
      font-size:20px !important;
    }



   
  }

  @media screen and (max-width: $break-min-ipad) {
    .btn-back {
      font-size: 16px;
      padding: 10px 40px;
    }

    .question_block {
      .btn-date {
        label {
          background: rgba(0, 0, 0, 0.562);
          border: 1px solid $color_2;
          font-size: 16px;

          &.btn-cml {
            padding: 10px 40px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .next-btn {
      bottom: 5rem !important;
      padding: 25px;
    }
  }
  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .errormsg {
    color: white;
    background-color: #ff0000;
    padding: 5px;
    border-radius: 5px;
  }

  /*==================*/
  .errormsg1 {
    color: white;
    background-color: #ff0000;
    padding: 5px;
    width: 33%;
    border-radius: 5px;
  }

  .errormsg2 {
    color: white;
    background-color: #ff0000;
    padding: 5px;
    width: 55%;
    border-radius: 5px;
  }

  .errormsg3 {
    color: white;
    background-color: #ff0000;
    padding: 5px;
    width: 54%;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
  }

  /*==================*/

  .select-box {
    margin-bottom: 1rem;
  }

  .hover-display {
    &:hover {
      color: #000;
    }
  }

  .skip-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #0070ba !important;
    text-decoration: underline;
    cursor: pointer;
  }

  .efct_filed {
    animation: blinkBG 1.5s infinite;
  }

  @keyframes blinkBG {
    0% {
      box-shadow: 0 0 0 0 rgb(223, 60, 114);
    }

    70% {
      box-shadow: 0 0 0 30px rgba(255, 177, 66, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
  }

  .skip_button{
		padding:5px 15px;
		border-radius: 5px;
	  }
}

$size: 150px;

.loadcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
  width: 50%;
}

@keyframes rotate {
  0% {
    transform: rotateX(-37.5deg) rotateY(45deg);
  }

  50% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }

  100% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

.cube {
  position: absolute;
  width: 190px;
  height: 190px;
  // right: 40%;
  // top: 21%;
}

.sides {
  animation: rotate 3s ease infinite;
  animation-delay: .8s;
  transform-style: preserve-3d;
  transform: rotateX(-37.5deg) rotateY(45deg);
}

.cube .sides * {
  box-sizing: border-box;
  background-color: rgba(232, 79, 202, 0.5);
  border: $size / 10 solid white;
}

@mixin side($name, $rotate, $i) {
  .#{$name} {
    animation: #{$name}-animation 3s ease infinite;
    animation-delay: $i * 100ms;
    transform: #{$rotate} translateZ($size);
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }


  @keyframes #{$name}-animation {
    0% {
      opacity: 1;
      transform: #{$rotate} translateZ($size)
    }

    20% {
      opacity: 1;
      transform: #{$rotate} translateZ($size / 2)
    }

    70% {
      opacity: 1;
      transform: #{$rotate} translateZ($size / 2)
    }

    90% {
      opacity: 1;
      transform: #{$rotate} translateZ($size)
    }

    100% {
      opacity: 1;
      transform: #{$rotate} translateZ($size)
    }
  }
}

.cube .sides {
  @include side("top", rotateX(90deg), 0);
  @include side("bottom", rotateX(-90deg), 0);
  @include side("front", rotateY(0deg), 1);
  @include side("back", rotateY(-180deg), 1);
  @include side("left", rotateY(-90deg), 1);
  @include side("right", rotateY(90deg), 1);
}

.text {
  margin-top: $size * 3;
  color: rgba(242, 119, 119, 1);
  font-size: 1.5rem;
  width: 100%;
  font-weight: 600;
  text-align: center;
}

.newscontainer {
  //border:1px solid pink;
  //width:720px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

}

.title {
  display: inline;
  position: relative;

}

.title1 {
  // animation-name: intro;
  // animation-duration:0.3s;
  // animation-iteration-count:1;
  // animation-timing-function:ease;
  //animation-delay:1s;
  animation-fill-mode: backwards;
  font-size: 33px;
  font-weight: 400;
  line-height: 45px;
  text-align: center;
  color: #000;
}

@keyframes intro {
  0% {
    top: 150px;
  }

  98% {
    top: -5px;
  }

  100% {
    top: 0%;
  }
}

.title2 {
  animation-name: intro;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  font-size: 33px;
  line-height: 45px;
  text-align: center;
  color: #000;
}

.center-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  margin-top: 27%;
  padding: 22px 20px 30px;
  background: #fff;
  color: #000;
  border-radius: 10px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

/*================================================
END - MEDIA QUERY
==================================================*/

