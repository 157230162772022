
  /*
*******************************************
Template Name: Clear Low
Author: ABC
Date: 27-12-2022
Template By: Vandalay Designs
Copyright 2020-2022 Vandalay Business Solution

* This file contains the styling for laonclaims.co.uk, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - RESET
04 - BODY 
05 - HEADER SECTION
06 - MAIN SECTION
07 - FOOTER 
09 - MEDIA QUERY

============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/

$color_1: #232355;
$color_2: #eb2164;
$color_3: #000;
$color_4: #fff;
$color_5: #ff357a;
$color_6: #f1f1f2;
$color_7: #e3e3e3;
$color_8: #9c9c9c;
$color_8: #343a40;
$popTransp: rgba(0, 0, 0, 0.425);
// ======font styles===============

$font_family_1: "Titillium Web", sans-serif;

$font_size_1: 14px;
$font_size_2: 22px;
$font_size_3: 28px;
$font_size_4: 26px;
$font_size_5: 18px;
$font_size_6: 15px;
$font_size_7: 17px;
$font_size_8: 20px;

$font-bold: bold;
$font-w600: 600;
$font-w700: 700;
$font-w900: 900;

$shadowLight: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$shadow01: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
  rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

// ======border===========
.border {
  border: 1px solid $color_3;
}
/*================================================
02 - Sass Mixins
==================================================*/

@mixin bnt-style {
  background: $color_5;
  color: $color_4;
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: $font-w600;
  display: inline-block;
  text-decoration: none;
  transition: ease-in 0.4s;
  outline: navajowhite;
  border: 0;

  &:hover,
  &:focus {
    color: $color_4;
    transition: ease-in 0.4s;
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: $color_2;
  }
}

@mixin comTitle($textSize) {
  font-size: ($textSize);
  font-family: $font_family_1;
}

/*================================================
   End Sass Mixins
==================================================*/
.com_bnt {
  @include bnt-style;
}
/*================================================
03 - RESET
==================================================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
  text-decoration: none;
}

p {
  font-size: 16px;
  line-height: 22px;
}
a {
  cursor: pointer;
  text-decoration: none;
  line-height: 20px;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: disc;
}

.d_hide,
.dnone {
  display: none !important;
}

/*================================================
04 - BODY STYLES
==================================================*/


html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 100%;
}
body {
  font-family: $font_family_1 !important;
  font-size: 1rem;
  line-height: 1rem;
  background-color: $color_1;
  color: $color_4;
}

/*================================================
END - BODY STYLES
==================================================*/

.under_line {
  text-decoration: underline;
}
 
.comm_title {
  @include comTitle(2rem);
  color: $color_1;
  font-weight: $font-w600;
  margin-bottom: 1rem;
}

.btn-lgr {
  max-width: 600px;
  padding: 1.5rem 3rem;
  font-size: 1.8rem;
}

/*================================================
05 - MAIN SECTION
==================================================*/
.main-start {
   
  p {
    font-size: 20px;
    color: $color_2;
    
  }
  &.bg {
    &::before {
      background: url("../img/img-bg1.png") no-repeat;
      content: "";
      height: 100px;
      width: 100%;
      display: block;
      background-size: cover;
    }
  }
}

.sec-nav-bars {
  background: $color_4;
  border-bottom: 1px solid $color_7;
  .logo {
    display: inline-block;
    padding: 5px;
    img {
      width: 65px;
    }
  }
}

.form-wrap{
  .error {
    background: $color_2;
    margin-top: 4px;
    border-radius: 5px;
    padding: 2px 6px;
    font-size: 14px;


  }
  .form-style {
    outline: none;
    border: 0;
    &.form-select {
      padding: 12px 10px;
    }
  }
  .lookup-filed {
    input {
      width: 70%;
    }
  }
  
}

.noborder{
  border: none;
}

table{
  margin-bottom: 15px;

  tr{
    border-bottom: 1px solid #ffffffff;
  }
  td,th{
    padding: 10px;
    line-height: 25px;
  }
}
h5{
  font-size: 26px;
}
.overflow-x{
  overflow-x: auto;
}
.headings{
  font-size: 22px;
}
a{
  color: #0db9fd;

  &:hover{
    text-decoration: underline;
    color: #0aa7ca;
  }
}

li{
  padding-bottom: 10px;
  line-height: 25px;
}

.popup-style {
  background: $color_1;
  color: $color_4;
  h1 {
    font-size: 26px;
    line-height: 30px;
  }
}

/*================================================
07 - FOOTER SECTION
==================================================*/


footer {
  background: $color_8;
  color: $color_4;
  font-size: 13px;
  .ft-menu {
    li {
      display: inline-block;
      a {
        padding: 5px;
        font-size: 14px;
        color: $color_4;

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  p {
    font-size: 14px;
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/*================================================
09 - MEDIA QUERY
==================================================*/

$break-large: 1367px;
$break-ipad-pro: 1200px;
$break-min-ipad: 768px;
$break-max-ipad: 991px;
$break-min-tab: 577px;
$break-max-tab: 768px;
$break-mob: 576px;
$break-smlmob: 391px;
$break-ex_smlmob: 322px;
@media (min-width: 1400px) {
  .container {
    max-width: 1140px !important;
  }
}
 
 
// maxwidth  991px
@media screen and (max-width: $break-max-ipad) {
  .com_bnt {
    @include bnt-style;
    padding: 0.8rem 2rem;
  }
   
  .main-start {
    &.bg {
      &::before {
        height: 60px;
      }
    }
  }
}

@media (max-width: 768px) {
  table{
    td,th{
      font-size: 13px;
    }
  }
  h1{
    font-size: 22px;
  }
  h4{
    font-size: 18px;
  }
  h5{
    font-size: 17px;
  }
  .headings{
    font-size: 15px;
  }
  p{
    font-size: 13px;
  }
  li{
    font-size: 13px;
  }
}
 
/*================================================
END - MEDIA QUERY
==================================================*/
