.previewPage {
    
        background: #eee;
    color: black;

    .container-1 {
        padding: 80px 80px 80px 80px;
        width: 70%;
        margin: 0px auto;
        font-family: 'Open Sans', sans-serif;
        background-color: white;
        min-height: 1180px;
    }

    h4 {
        font-size: 18px;
        color: #000000;
        text-align: center;
        font-weight: 700;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 14px;
        color: #000;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        font-weight: 600;
    }

    li {
        font-size: 14px;
        padding-right: 10px;
        font-weight: 600;
        margin-top: 18px;
    }

    .spl {
        font-size: 14px;
        padding-right: 10px;
        font-weight: 600;
        list-style-type: none;
        display: inline-flex;
    }


    footer {
        position: fixed;
        width: 100%;
        bottom: 0;
        text-align: center;
    }

    .detail {
        width: 100%;
        margin-top: 50px !important;
    }

    table {
        width: 100%;
    }

    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
        font-size: 13px;
        padding: 10px;
    }

    .td1 {
        width: 30% !important;
    }

    .td2 {
        width: 70% !important;
    }

    a {
        font-size: 14px !important;
    }

    .table-1a { 
        border: 0px solid black;
        tr{ 
            td{ 
                border: 0px;
            
                a{ 
                    color: black;
                    text-decoration: none;
                    font-weight: 600;
                }

            }
        }
    }

    @media only screen and (max-width: 600px) {

        .container-1 {
            padding: 5% 5% 13%;
            width: 98%;
            margin: auto;
            font-family: 'Open Sans', sans-serif;
            background-color: white;
            min-height: 500px;
        }

        .ps-5,
        .px-5 {
            padding: 0px !important;
        }


        .my-5 {
            margin: 0 !important;
        }
    }
}
// body{
//     background: #eee;
// }
