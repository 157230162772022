@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");
@import "../src/assets/css/CL_LS_V3.scss";
@import "../src/assets/css/questionnaire.scss";
@import "../src/assets/css/signature.scss";
@import "../src/assets/css/success.scss";
@import "../src/assets/css/thankyou.scss";
@import "../src/assets/css/privacy_complaints.scss";
@import "../src/assets/css/pre_contract_info.scss";
@import "../src/assets/css/Terms_and_Conditions.scss";
@import "../src/assets/css/preview.scss";
@import "../src/assets/css/signatureV2.scss";
@import "../src/assets/css/questionnaire_followup.scss";
@import "../src/assets/css/vulnerable_policy.scss";
@import "../src/assets/css/questionnaire_CL_LS_V7.scss";
@import "../src/assets/css/questionnaire_CL_LS_V8.scss";
@import "../src/assets/css/CL_LS_V7.scss";


