.vulnerablepolicy{
.headings{
    width:100%; 
    text-align:center;
    font-size:18px;
 }
 p{
    font-size: 14px;
 }

 
 .main-table{
    border: none !important;
    border-collapse: unset;
    td{
        padding: 0px;
    }
 }

 
 .bullet-points{
    padding-left: 40px;
    li{
        list-style-type: disc;
    }
 }

 .round-points{
   padding-left: 40px;
   li{
       list-style-type:circle;
   }
 }
 
 .alink{
   text-decoration: underline;
 }
 .numeric_points{
   padding-left: 40px;
   li{
       list-style-type:auto;
   }
 }

 u {
   text-decoration: underline;
 }


 li{
    font-size:13px;
 }
}