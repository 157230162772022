.signature_div{
  $color_1: #232355;
$color_2: #eb2164;
$color_3: #000;
$color_4: #fff;
$color_5: #ff357a;
$color_6: #f1f1f2;
$color_7: #e3e3e3;
$color_8: #9c9c9c;
$color_8: #343a40;
$break-large: 1367px;
$break-ipad-pro: 1200px;
$break-min-ipad: 768px;
$break-max-ipad: 991px;
$break-min-tab: 577px;
$break-max-tab: 768px;
$break-mob: 576px;
$break-smlmob: 391px;
$break-ex_smlmob: 322px;
$font-w600: 600;
$font_family_1: "Titillium Web", sans-serif;
@mixin bnt-style {
  background: $color_5;
  color: $color_4;
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: $font-w600;
  display: inline-block;
  text-decoration: none;
  transition: ease-in 0.4s;
  outline: navajowhite;
  border: 0;

  &:hover,
  &:focus {
    color: $color_4;
    transition: ease-in 0.4s;
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: $color_2;
  }
}
.com_bnt {
  @include bnt-style;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 100%;
}
  font-family: $font_family_1 !important;
  font-size: 1rem;
  line-height: 1rem;
  background-color: $color_1;
  color: $color_4;

header {
  .brand_logo {
    text-align: center;
    padding: 1rem 0;
    img {
      height: 40px;
    }
  }
}
.main-start {
    
  p {
    font-size: 20px;
    color: $color_2;
  }
  &.bg {
    &::before {
      background: url("../img/img-bg1.png") no-repeat;
      content: "";
      height: 100px;
      width: 100%;
      display: block;
      background-size: cover;
    }
  }
}
.sec-nav-bars {
  background: $color_4;
  border-bottom: 1px solid $color_7;
  .logo {
    display: inline-block;
    padding: 5px;
    img {
      width: 200px;
    }
  }
}

.body_signature {
  .sign_header {
    background: $color_4;
    color: $color_3;
    text-align: center;
    border-radius: 20px 20px 0 0;

    padding: 1rem 3rem;
    h1 {
      font-size: 24px;
      color: $color_3;
      font-weight: bold;
    }
  }
  max-width: 600px;
  margin: 0 auto 4rem auto;
  background: $color_4;
  border-radius: 20px;
  p {
    color: $color_3;
    line-height: 22px;
  }
  

  .inner {
    padding: 0 20px 20px 20px;
    color: $color_3;

    .list_points {
      // transform: translate(21%, 0%);

      margin: 0 auto;
      width: 60%;
      li {
        padding: 0.8rem 0.4rem;
        position: relative;
        padding-left: 2rem;
        &::before {
          content: "";
          background: url(../img/icon_01.png) no-repeat;
          width: 31px;
          height: 21px;
          display: block;
          display: inline-block;
          margin-right: 10px;
          background-size: 25px 15px;
          background-position: 0 6px;
          position: absolute;
          left: 0;
          top: 6px;
        }
      }
    }
  }
  
  .signature {
    background: $color_6 url(../img/signhere.png) no-repeat center;
    min-height: 250px;
    border: 2px solid #c3c3c3;
    border-radius: 20px;
  }

  .sign_footer {
    padding: 0 20px 20px 20px;
    .clear_btn {
      background: $color_8;
    }
    input {
      position: relative;
      top: 4px;
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: $break-max-ipad) {
    .signature_div{
      .body_signature{
          margin: 20px auto 4rem auto;
      }
    }
    .com_bnt {
      @include bnt-style;
      padding: 0.8rem 2rem;
    }
  }
}
.section-next {
  background: $color_4 url(../img/bottomwave.png)  repeat-x;
background-position:top center;
  padding: 60px 0;
  width: 100%;
  .btn-next {
    font-size: 20px;
    margin-top: 60px;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
.show{
  transition: opacity 0.15s linear;
  display: block;
  
}
.hide{
  display: none;
}
.sign_msg{
  color: #fff;
  background: #ff0000;
  width: 100%;
  padding: 2px 5px;
  text-align: center;
  border-radius: 4px;
  font-size: 13px;
 
}
.signmodel-wrap {
  .btn-close {
    position: absolute;
    right: 19px;
    top: 15px;
    z-index: 5;
  }
  ul {
    margin-left: 30px;
    margin-bottom: 30px;
    li {
      padding: 4px;
      line-height: 20px;
    }
  }
  h4 {
    font-weight: bold;
    margin-bottom: 10px;
    &::before {
      width: 20px;
      height: 2px;
      background: $color_2;
      content: "";
      display: inline-block;
      margin-right: 10px;
    }
  }
  table {
    tr {
      td {
        line-height: 24px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .body_signature {
    margin: 20px auto 4rem auto;
    .sign_header{
        padding:1px 20px 10px;
         h1{
            font-size: 19px;
         }
    }
    p{
        line-height: 20px;
        font-size: 14px;
    }
    .inner {
      .list_points {
        li {
          padding-left: 3rem;
        }
        margin: 0 auto;
        width: 100%;
      }
    }


  }
}

footer {
  background: $color_8;
  color: $color_4;
  font-size: 13px;
  .ft-menu {
    li {
      display: inline-block;
      a {
        padding: 5px;
        font-size: 14px;
        color: $color_4;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 22px;
    color: $color_4;
  }
}
}
