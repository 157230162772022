.ADV1_Contact {
  .logo{
    text-align: center;
    img{
        height: 41px;
    }
}
.contact-footer {
    background: #ebebeb !important;
}
.contact_sec {
    padding: 50px 0px 0px 0px;
.contact_area {
    background: #f7f7f7;
    border: 1px solid #d2d2d2;
    box-shadow: 0 3px 0 #cfcfcf;
    border-radius: 10px;
    padding: 20px;
    .form-group {
        margin-bottom: 1rem;
    }
    .form-group label {
        font-weight: bold;
        color: #656565;
        margin-bottom: 0.5rem;
    }
}
}
}
