 .thankyou {
  $color_1: #232355;
  $color_2: #eb2164;
  $color_3: #000;
  $color_4: #fff;
  $color_5: #ff357a;
  $color_6: #f1f1f2;
  $color_7: #e3e3e3;
  $color_8: #9c9c9c;
  $color_8: #343a40;
  $break-large: 1367px;
  $break-ipad-pro: 1200px;
  $break-min-ipad: 768px;
  $break-max-ipad: 991px;
  $break-min-tab: 577px;
  $break-max-tab: 768px;
  $break-mob: 576px;
  $break-smlmob: 391px;
  $break-ex_smlmob: 322px;
  $font-w600: 600;
  $font_family_1: "Titillium Web", sans-serif;
    h1 {
      font-size: 30px;
    }
    .card{
      &.card-dx {
        border-radius: 10px;
      }
      .card-header {
        background: $color_2;
        border-radius: 10px 10px 0 0;
        h3 {
         margin-bottom: 0;
        text-align: center;
        font-size: 22px;
        }
      }
      .card-body {
        color: $color_3;
        background: $color_6;
        p {
          font-size: 15px;
        }
        h4 {
          font-size: 20px;
        }
      }
      .card-footer {
        background: $color_2;
        border-radius: 0 0 10px 10px ;
      }
      .choosefile {
        border: 1px solid #eee;
        background: $color_4;
        border-radius: 5px 0px 0px 5px;
        color: $color_1;
        padding: 9px;
        input[type="file"] {
          opacity: 0;
          position: absolute;
          top: 0;
        }
      }
    }
    .card-document {
      h3 {
        font-size: 22px;
      }
      border-radius: 10px;
      color: $color_3;
      background: $color_4;
    }
    .btn-docsubmit {
      background: #28a745;
      border-color: #28a745;
      padding: 15px 0;
      font-size: 20px;
    }
 
 .thankyou section {
  padding: 0px 0px !important;

  .skip_button{
		padding:5px 15px;
		border-radius: 5px;
	  }
}


.btn-danger1{
    color: white;
    background-color: #ff0000;
    padding: 5px;
    width:100%;
    border-radius:5px;
}

  
  /////////////Media Query/////////////////
  
  $break-large: 1367px;
  $break-ipad-pro: 1200px;
  $break-min-ipad: 768px;
  $break-max-ipad: 991px;
  $break-min-tab: 577px;
  $break-max-tab: 768px;
  $break-mob: 576px;
  $break-smlmob: 391px;
  $break-ex_smlmob: 322px;
  
 
  @media screen and (max-width: $break-max-ipad) {
    .thankyou {
      
    .card {
      .card-header{
        h3 {
          font-size: 20px;
        }
      }
    }
    }
  }
  .sec-nav-bars {
   // background: $color_4;
    border-bottom: 1px solid $color_7;
    .logo {
      display: inline-block;
      padding: 5px;
      img {
        width: 65px;
      }
    }
  }


 
  @media screen and (max-width: $break-max-tab) {
  
    .thankyou {
      h1{
        font-size: 22px;
      }
    .card {
      .card-header{
        h3 {
          font-size: 20px;
        }
      }
    }
    }
    

  }
 }
  
  