.Questionnaire {
	$color_1: #232355;
	$color_2: #eb2164;
	$color_3: #000;
	$color_4: #fff;
	$color_5: #ff357a;
	$color_6: #f1f1f2;
	$color_7: #e3e3e3;
	$color_8: #9c9c9c;
	$color_8: #343a40;
	.footer_anim{
		position: absolute;
		left:0px;
		bottom: 0;
		width: 100%;
	
	  .ft_car {
		position: absolute;
		left:0px;
		bottom: 0;  
	  } 
	}
	.title-text {
	  font-size: 30px;
	}
	.option-btn {
		background: $color_4;
		color: #000;
		font-size: 20px;
		margin: 4px;
		border-radius: 5px;
	  input {
		 opacity: 0;
		  visibility: hidden;
		  position: absolute;
	  }
	  label {
		padding: 20px 40px;
		font-size: 22px;
		border-radius: 5px;
		cursor: pointer;
	   
	  }
	  input:checked + label { 
		background: $color_2;
		color: $color_4;
		font-weight: bold;
	  }
	  
	
	}
	
	.optionlist {
	   li {
		display: flex;
		border-bottom: 1px solid #39396c;
		padding: 10px 0;
		margin-bottom: 5px;
		cursor: pointer;
		transition: all ease-in 0.1s;
		&.active {
		background:  #363681;
		padding-left: 10px;
		transition: all ease-in 0.1s;
		border-radius: 5px;
	  }
	  
		input[type='checkbox'] {
		  text-align: left ;
		  width: 20px !important;
		  height: 20px !important;
		  margin-right: 10px;
		  border-radius: 5px;
		  min-width: 20px;
		}
		label {
		  text-align: left;
		  line-height: 20px;
		  font-size: 15px;
		  position: relative;
		  cursor: pointer;
		}
	   }
	  
	}
	.optionalarea {
	  .form-text-area {
		max-width: 600px;
		margin: 0 auto;
	  
	   }
	
	}

	////// edited css ////
	 
	.ques1-btn{
		flex-wrap: wrap;
    padding: 0px 120px;
	}
	
	.checklist {
		display: block;
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	   
	
		input {
			position: absolute;
			opacity:0;
			cursor: pointer;
			height: 10px;
			width: 10px;
		//  &:checked ~ .cbl  {
		//   background: red;
		//  }
			&:checked {
				~ {
					.checkmark {
						background-color:  $color_2;
						&:after {
							display: block;
						}
					}
				}
			}
		}
		.checkmark {
			&:after {
				left: 6px;
		  top: 2px;
		  width: 7px;
		  height: 14px;
				border:1px solid #fff;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
	  border-radius: 2px;
		background-color:  #eee;
		&:after {
			content: "";
			position: absolute;
			display: none;
		}
	}
	
	
	
	.form-wraper {
	  position: relative;
	  border-radius: 10px;
	 
	  &.box_thakmsng {
		&::after {
		  background: none;
		}
	  }
	
	  &::after {
	  content: "";
	  display: block;
	  position: absolute;
	  bottom: -40px;
	  left: 0;
	  right: 0;
	  width: 100%;
	  height:40px;
	  background-size: 98%;
	
	}
	  .title {
		max-width: 60%;
		margin: 30px auto 20px auto;
		font-size:30px;
	  }
	  .title2 {
		font-size:20px;
	  }
	
	  
	
	  .inpt-style-frst {
		&:focus {
		  outline: none !important;
		}
		border-radius: 0  .375rem .375rem 0;
		&.inpt-height {
		  height: 55px;
		  
		}
		
		.gb {
		  background: $color_1;
		  height: 100%;
		  padding: 20px;
		  font-size: 22px;
		  font-weight: bold;
		  color: $color_3;
		  border-radius:   .375rem 0  0 .375rem ;
		}
	  }
	  .inpt-style {
		.inpt-height {
		  height: 55px;
		}
	  }
	  .com_bnt {
		text-transform: uppercase;
	  }
	
	  .list-select {
		  height: 150px;
		  overflow-y: auto;
		  li {
			background: $color_3;
			border-radius: 30px;
			color: $color_5;
			margin-bottom: 8px;
			input {
			  display: none;
	
			}
			label {
			  background: $color_4;
			  color: $color_5;
			  display: inline-block;
			  padding:15px 20px;
			  cursor: pointer;
			  border-radius: 30px;
			}
			input:checked ~ label {
			  background: $color_1;
			  color: $color_3;
		 
	
			}
		  }
	  }
	
	  /////////////////////////
	  .progressb  { 
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
		.progress-bar {
		  background-color: $color_3;
		  border-radius: 10px;
		  height: 5px;
		  position: relative;
		  overflow: hidden;
		 
		  transition: linear  0.1s;
	
			[data-progress] {
			background-color: $color_1;
			border-radius: 10px;
			height: 100%;
			width: 0;
			line-height: 30px;
			position: absolute;
		  
		  }
		}
	}
	.controle_btn {
	  .com_bnt  {
		font-size: 20px;
		width: 200px;
		padding: 19px;
	  }
	}
	
	.select-filed {
	  max-width: 600px;
	  margin: 0 auto;
	
	   select, input  {
		width: 100%;
		padding: 10px;
		border-radius: 5px;
		box-sizing: border-box;
		font-size:18px;
	   }
	   input {
		text-align: center;
	   }
	   input::placeholder {
		text-align: center;
	   }
	
	
	}
	
	//////////////////////////////////////////////////////////////
	 // Progress Circle
	
	$borderWidth: 10px;
	$animationTime: 0.5s;
	$border-color-default: #3535ab;
	$border-color-fill: $color_2;
	$size: 120px;
	$howManySteps: 100; //this needs to be even.
	
	.progress {
	  width: $size;
	  height: $size !important;
	  line-height: $size;
	  margin: 0 auto;
	  box-shadow: none;
	  position: relative;
	  background: none !important;
	  margin-bottom: 30px;
	  color: $color_1;
	  &:after {
		content: "";
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: $borderWidth solid $border-color-default;
		position: absolute;
		top: 0;
		left: 0;
		background-color: $color_4;
	  }
	  > span {
		width: 50%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		z-index: 1;
	  }
	  .perce {
		font-size: 28px !important;
		font-weight: 100;
	  }
	
	  .progress-left {
		left: 0;
	  }
	  .progress-bar {
		width: 100%;
		height: 100%;
		background: none;
		border-width: $borderWidth;
		border-style: solid;
		position: absolute;
		top: 0;
		border-color: $border-color-fill;
	  }
	  .progress-left .progress-bar {
		left: 100%;
		border-top-right-radius: ($size/2);
		border-bottom-right-radius: ($size/2);
		border-left: 0;
		-webkit-transform-origin: center left;
		transform-origin: center left;
		//animation: loading-2 1.5s linear forwards 1.8s;
	  }
	  .progress-right {
		right: 0;
		.progress-bar {
		  left: -100%;
		  border-top-left-radius: ($size/2);
		  border-bottom-left-radius: ($size/2);
		  border-right: 0;
		  -webkit-transform-origin: center right;
		  transform-origin: center right;
		  //animation: loading-1 1.8s linear forwards;
		}
	  }
	  .progress-value {
		display: flex;
		border-radius: 50%;
		font-size: 1rem;
		text-align: center;
		line-height: 20px;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		font-weight: 300;
		z-index: 9;
		
		span {
		  font-size: 12px;
		  text-transform: uppercase;
		}
	  }
	}
	/* This for loop creates the necessary css animation names 
	Due to the split circle of progress-left and progress right, we must use the animations on each side. 
	*/
	@for $i from 1 through $howManySteps {
	  $stepName: ($i * (100 / $howManySteps));
	
	  //animation only the left side if below 50%
	  @if $i <= ($howManySteps/2) {
		.progress[data-percentage="#{$stepName}"] {
		  .progress-right .progress-bar {
			animation: loading-#{$i} $animationTime linear forwards;
		  }
		  .progress-left .progress-bar {
			animation: 0;
		  }
		}
	  }
	  //animation only the right side if above 50%
	  @if $i > ($howManySteps/2) {
		.progress[data-percentage="#{$stepName}"] {
		  .progress-right .progress-bar {
			animation: loading-#{($howManySteps/2)}
			  $animationTime
			  linear
			  forwards; //set the animation to longest animation
		  }
		  .progress-left .progress-bar {
			animation: loading-#{$i -
			  ($howManySteps/2)}
			  $animationTime
			  linear
			  forwards
			  $animationTime;
		  }
		}
	  }
	}
	
	//animation
	@for $i from 1 through ($howManySteps/2) {
	  $degrees: (180/ ($howManySteps/2));
	  $degrees: ($degrees * $i);
	  @keyframes loading-#{$i} {
		0% {
		  -webkit-transform: rotate(0deg);
		  transform: rotate(0deg);
		}
		100% {
		  -webkit-transform: rotate($degrees);
		  transform: rotate(#{$degrees}deg);
		}
	  }
	}
	
	// End Progress
	 
	 
	
	 
	 
	 
	
	
	
	
	}
	
	
	
	.thankyoubody {
	  height: 100vh;
	  .thankyou { 
		  .inpt-width {
			max-width: 350px ;
			margin: 0 auto;
		  }
	 
		.title {
		  font-size:26px;
		  width: 60%;
		  margin: 0 auto;
		}
		p {
		  font-size: 18px;
		}
		h3 {
		  font-size: 18px;
		}
		
	  }
	
	}
	
	.slide-top {
	  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	  animation-delay: .6s;
	}
	.slide-left {
	  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	  animation-delay: .6s;
	}
	
	
	.height-scale {
	  -webkit-animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	  animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	}
	
	.scale-down-ver-center {
		-webkit-animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
				animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
			  animation-delay: .3s;
	}
	
	
	///////////////////////////  ////////////////////////
	 
	
	@-webkit-keyframes slide-top {
	  0% {
		-webkit-transform: translateY(30px);
		transform: translateY(30px);
		opacity: 0;
	  }
	  100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	  }
	}
	@keyframes slide-top {
	  0% {
		-webkit-transform: translateY(30px);
		transform: translateY(30px);
		opacity: 0;
	  }
	  100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	  }
	}
	
	
	
	@keyframes slide-left  {
	  0% {
		-webkit-transform: translateX(30px);
		transform: translateX(30px);
		opacity: 0;
	  }
	  100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	  }
	}
	
	///////////////////////////  ////////////////////////
	
	@-webkit-keyframes height-scale {
	  0% {
		-webkit-transform:  height 0.4s linear;
		transform:  height 0.4s linear;
		opacity: 0;
		//height: 0;
	  }
	  100% {
		-webkit-transform:  height 0.4s linear;
		transform:  height 0.4s linear;
		opacity: 1;
		//height: 350px;
	  }
	}
	@keyframes height-scale {
	  0% {
		-webkit-transform:  height 0.4s linear;
		transform:  height 0.4s linear;
		opacity: 0;
		//height: 0;
	  }
	  100% {
		-webkit-transform:  height 0.4s linear;
		transform:  height 0.4s linear;
		opacity: 1;
		//height: 350px;
	  }
	}
	 
	
	
	@-webkit-keyframes scale-down-ver-center {
	  0% {
		-webkit-transform: scaleY(0);
				transform: scaleY(0);
	  }
	  100% {
	   
				-webkit-transform: scaleY(1);
				transform: scaleY(1);
	
	  }
	}
	@keyframes scale-down-ver-center {
	  0% {
				-webkit-transform: scaleY(0);
				transform: scaleY(0);
	  }
	  100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	  }
	}
	
	
	
	
	  
	  /////////////Media Query/////////////////
	  
	  $break-large: 1367px;
	  $break-ipad-pro: 1200px;
	  $break-min-ipad: 768px;
	  $break-max-ipad: 991px;
	  $break-min-tab: 577px;
	  $break-max-tab: 768px;
	  $break-mob: 576px;
	  $break-smlmob: 391px;
	  $break-ex_smlmob: 322px;
	 

	  @media screen and (max-width: $break-max-ipad){
		.ques1-btn{
			flex-wrap: wrap;
			padding: 0px 0px;
		}
	  }
	
	  @media screen and (max-width: $break-max-tab) {
		 
		 
	   
		
		.title-text {
		  font-size: 22px;
		}
		.footer_anim {
		  .ft_car {
			width: 70px;
		  }
		}
	
		.option-btn {
		  label  {
			padding: 14px 25px;
			font-size: 20px;
		  }
		}
	
		.form-wraper {
		  &::after {
			background-size: 98%;
		  }
	
		  .title {font-size: 18px;}
			.com_bnt {
			  font-size: 14px;
			}
	
		  .controle_btn {
			.com_bnt  {padding:16px; font-size: 18px;}
		  }
		  .checklist {
			padding-left: 25px;
			&.active {
			  background: $color_2;
			}
		  }
		  .checkmark {
			width: 15px;
			height: 15px;
			top: 5px;
			&::after {
			  left: 5px;
			  top: 2px;
			  width: 5px;
			  height: 10px;
			}
		  }
		}

	  }
	  .skip_button{
		padding:5px 15px;
		border-radius: 5px;
	  }
}